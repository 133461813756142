import { useState, useEffect} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// Import Pages
import Home from '../pages/Home';
import AboutMe from '../pages/AboutMe';
import Hobbies from '../pages/Hobbies';
import NotFound from '../pages/NotFound';

const Content = () => {
    const location = useLocation();
    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    useEffect(() => {
        if (location !== displayLocation) setTransistionStage("fadeOut");
    }, [location, displayLocation]);

    return (
        <main className="main-content">
            <div 
                className={`${transitionStage}`}
                onAnimationEnd={() => {
                    if (transitionStage === "fadeOut") {
                        setTransistionStage("fadeIn");
                        setDisplayLocation(location);
                    }
                }}>

                <Routes location={displayLocation}>
                    <Route path="/" element={<Home />}>Home</Route>
                    <Route path="/about-me" element={<AboutMe />}>About me</Route>
                    <Route path="/hobbies" element={<Hobbies />} title="Hobbies">Hobbies</Route>
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </div>
        </main>
    );
};

export default Content;