// Budapest
import ImgBudapest_1 from '../assets/img/section-adventures/Budapest/Budapest-1.jpg';
import ImgBudapest_2 from '../assets/img/section-adventures/Budapest/Budapest-2.jpg';
import ImgBudapest_3 from '../assets/img/section-adventures/Budapest/Budapest-3.jpg';
import ImgBudapest_4 from '../assets/img/section-adventures/Budapest/Budapest-4.jpg';

// Debrecen
import ImgDebcrecen_1 from '../assets/img/section-adventures/Debrecen/Debrecen-1.jpg';
import ImgDebcrecen_2 from '../assets/img/section-adventures/Debrecen/Debrecen-2.jpg';
import ImgDebcrecen_3 from '../assets/img/section-adventures/Debrecen/Debrecen-3.jpg';
import ImgDebcrecen_4 from '../assets/img/section-adventures/Debrecen/Debrecen-4.jpg';

// To The Border
import ImgToTheBorder_2 from '../assets/img/section-adventures/To-The-Border/To-The-Border-2.jpg';

import { useTranslation } from "react-i18next";

const Home = () => {
    const { t } = useTranslation();
    document.title = 'Szabó Csaba-Sándor';

    return (
        <section className="section-home">
            <h1 className="section-home__title">{t('adventures')}</h1>
            <div className="container" id="news_section">
                <div className="row-adventure">
                    <div className="row-adventure__column-text">
                        <h2 className="row-adventure__title">{t('debrecen_title')}</h2>
                        <p className="row-adventure__description">{t('debrecen_description')}</p>
                        <p className="row-adventure__date">{t('debrecen_date')}</p>
                    </div>

                    <div className="row-adventure__column-photo">
                        <img src={ImgDebcrecen_1} alt="Debrecen" className="row-adventure__photo row-adventure__photo--debrecen-1" />
                        <img src={ImgDebcrecen_2} alt="Debrecen" className="row-adventure__photo row-adventure__photo--debrecen-2" />
                        <img src={ImgDebcrecen_4} alt="Debrecen" className="row-adventure__photo row-adventure__photo--debrecen-4" />
                        <img src={ImgDebcrecen_3} alt="Debrecen" className="row-adventure__photo row-adventure__photo--debrecen-3" />
                    </div>
                </div>

                <div className="row-adventure">
                    <div className="row-adventure__column-photo">
                        <img src={ImgBudapest_1} alt="Debrecen" className="row-adventure__photo row-adventure__photo--budapest-1" />
                        <img src={ImgBudapest_2} alt="Debrecen" className="row-adventure__photo row-adventure__photo--budapest-2" />
                        <img src={ImgBudapest_3} alt="Debrecen" className="row-adventure__photo row-adventure__photo--budapest-3" />
                        <img src={ImgBudapest_4} alt="Debrecen" className="row-adventure__photo row-adventure__photo--budapest-4" />
                    </div>
                    <div className="row-adventure__column-text">
                        <h2 className="row-adventure__title">{t('budapest_title')}</h2>
                        <p className="row-adventure__description">{t('budapest_description')}</p>
                        <p className="row-adventure__date">{t('budapest_date')}</p>
                    </div>
                </div>

                <div className="row-adventure">
                    <div className="row-adventure__column-text">
                        <h2 className="row-adventure__title">{t('to_the_border_title')}</h2>
                        <p className="row-adventure__description">{t('to_the_border_description')}</p>
                        <p className="row-adventure__date">{t('to_the_border_date')}</p>
                    </div>

                    <div className="row-adventure__column-photo">
                        <img src={ImgToTheBorder_2} alt="To The Border 2" className="row-adventure__photo row-adventure__photo--to-the-board-2" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;