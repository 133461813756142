import MyLogo from '../assets/svg/MyLogo';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const HeaderHero = () => {
    const { t } = useTranslation();
    
    const scrollToNews = () => {
        const newsSection = document.querySelector('.section-home');
        newsSection.scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <header className="header-hero">
            <div className="hero-element">
                <NavLink to="/" className="hero-logo-link">
                    <MyLogo />
                </NavLink>
                
                <div className="hero-buttons">
                    <NavLink to="/about-me" className="hero-buttons__link">{t('about_me')}</NavLink>
                    <NavLink to="/hobbies" className="hero-buttons__link">{t('hobbies')}</NavLink>
                </div>
            </div>

            <div className="hero-scroll-down-wrapper" onClick={scrollToNews}>
                <i className="fa-solid fa-chevron-down hero-scroll-down"></i>
            </div>
        </header>
    );
};

export default HeaderHero;