import React from 'react';
import { useLocation } from 'react-router-dom';

// Import Components
import Languages from './components/Languages';
import Navigation from './components/Navigation';

// Import Layout
import Header from './layouts/Header';
import HeaderHero from './layouts/HeaderHero';
import Content from './layouts/Content';
import Footer from './layouts/Footer';

// Import SCSS file
import './sass/App.scss';

const App = () => {
    const location = useLocation();
    
    return (
        <div className="app">
            <Languages />
            <Navigation />
            {location.pathname === '/' ? <HeaderHero></HeaderHero> : <Header></Header>}
            <Content></Content>
            <Footer></Footer>
        </div>
    );
};

export default App;