import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

let language = localStorage.getItem('language') || 'hu';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: language,
    fallbackLng: language,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;