import { createSlice } from "@reduxjs/toolkit";

const initialLanguageState = {
    language: localStorage.getItem('language') || 'hu'
};

const languageSlice = createSlice({
    name: 'language',
    initialState: initialLanguageState,
    reducers: {
        changeLanguage(state, action) {
            state.language = action.payload;
        }
    }
});

export const languageActions = languageSlice.actions;
export default languageSlice.reducer;