import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const NotFound = () => {
    document.title = 'Szabó Csaba-Sándor - Not found';
    const { t } = useTranslation();

    return (
        <section className="section-not-found">
            <h1 className="section-not-found__title">{t('not_found')}</h1>
            <NavLink to="/" className="section-not-found__link">{t('not_found_link')}</NavLink>
        </section>
    );
};

export default NotFound;