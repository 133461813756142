import { createSlice } from "@reduxjs/toolkit";

const initialNavigationState = {
    showNavigation: false,
    navigationClasses: 'fa-solid fa-bars sidebar-navigation__icon',
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: initialNavigationState,
    reducers: {
        toggleNavigation(state) {
            state.showNavigation = !state.showNavigation;
        },

        toggleNavigationClasses(state) {
            if (state.showNavigation) {
                state.navigationClasses = 'fa-solid fa-xmark sidebar-navigation__icon';
            } else {
                state.navigationClasses = 'fa-solid fa-bars sidebar-navigation__icon';
            }
        }
    }
});

export const navigationActions = navigationSlice.actions;
export default navigationSlice.reducer;