import MyLogo from '../assets/svg/MyLogo';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <header className="header">
            <NavLink to="/">
                <MyLogo />
            </NavLink>
        </header>
    );
};

export default Header;