const MyLogo = () => {
    return (
        <svg width="400" height="90" version="1.1" viewBox="0 0 106.07 23.869" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-50.997 -23.992)">
                <text 
                    x="103.77872" 
                    y="32.290325" 
                    fill="#000000" 
                    fontFamily="Inter" 
                    fontSize="10.583px" 
                    strokeWidth=".26458" 
                    textAnchor="middle" 
                    xmlSpace="preserve">
                        
                    <tspan x="103.77872" y="32.290325" text-align="center">
                        {/* <tspan fill="#ff6600">Szabó </tspan> */}
                        <tspan fill="#ffffff">Szabó Csaba-Sándor</tspan>
                    </tspan>
                    <tspan x="103.77872" y="45.696274" fill="#ff6600" text-align="center">
                        &lt;web-developer&gt;
                    </tspan>
                    <tspan x="103.77872" y="58.925396" text-align="center"/>
                </text>
            </g>
        </svg>
    );
};

export default MyLogo;