import { useDispatch, useSelector } from "react-redux";
import { languageActions } from '../store/language';
import { useTranslation } from "react-i18next";

const Languages = () => {
    const { i18n } = useTranslation();
    const saveLanguage = (language) => {
        localStorage.setItem('language', language.language);
        document.querySelector('html').setAttribute('lang', language.language);
    };

    let language = useSelector(state => state.language);
    saveLanguage(language);

    const dispatch = useDispatch();
    const changeLanguageHandler = (event) => {
        let languageSelected = event.target.textContent;

        dispatch(languageActions.changeLanguage(languageSelected));
        i18n.changeLanguage(languageSelected);
        document.querySelector('html').setAttribute('lang', languageSelected);
    };

    return (
        <div className="languages">
            <span className={`languages__item ${language.language === 'hu' ? 'languages__item--active' : ''}`} onClick={changeLanguageHandler}>hu</span>
            <span className={`languages__item ${language.language === 'ro' ? 'languages__item--active' : ''}`} onClick={changeLanguageHandler}>ro</span>
            <span className={`languages__item ${language.language === 'en' ? 'languages__item--active' : ''}`} onClick={changeLanguageHandler}>en</span>
        </div>
    );
};

export default Languages;