import ImgWebProgramming from '../assets/img/section-hobbies/web-programming.png';
import ImgRubikCube from '../assets/img/section-hobbies/rubik-cube.jpg';
import ImgDance from '../assets/img/section-hobbies/dance.jpg';
import { useTranslation } from "react-i18next";

const AboutMe = () => {
    const { t } = useTranslation();
    document.title = `Szabó Csaba-Sándor - ${t('hobbies')}`;

    return (
        <section className="section-hobbies">
            <div className="breadcrumb">
            <h1 className="breadcrumb__title">{t('hobbies')}</h1>
                <a href="/" className="breadcrumb__link">{t('home')}</a>
                <span className="breadcrumb__separator"> &gt; </span>
                <span className="breadcrumb__last">{t('hobbies')}</span>
            </div>

            <div className="container">
                <div className="row-hobbies">
                    <div className="row-hobbies__column-text">
                        <h2 className="row-hobbies__title">{t('web_programming')}</h2>
                        <p className="row-hobbies__description">{t('web_programming_description')}</p>
                    </div>

                    <div className="row-hobbies__column-photo">
                        <img src={ImgWebProgramming} alt={t('web_programming')} className="row-hobbies__photo" />
                    </div>
                </div>

                <div className="row-hobbies">
                    <div className="row-hobbies__column-photo">
                        <img src={ImgRubikCube} alt={t('rubik_cube')} className="row-hobbies__photo" />
                    </div>
                    
                    <div className="row-hobbies__column-text">
                        <h2 className="row-hobbies__title">{t('rubik_cube')}</h2>
                        <p className="row-hobbies__description">{t('rubik_cube_description')}</p>
                    </div>
                </div>

                <div className="row-hobbies">
                    <div className="row-hobbies__column-text">
                        <h2 className="row-hobbies__title">{t('dance')}</h2>
                        <p className="row-hobbies__description">{t('dance_description')}</p>
                    </div>

                    <div className="row-hobbies__column-photo">
                        <img src={ImgDance} alt={t('dance')} className="row-hobbies__photo" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutMe;