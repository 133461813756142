import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from './navigation';
import languageReducer from './language';

const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        language: languageReducer
    }
});

export default store;