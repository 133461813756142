import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-get-in-touch">
                <div className="footer-get-in-touch__title">{t('get_in_touch')}</div>
                <ul className="footer-get-in-touch__list">
                    <li className="footer-get-in-touch__item">
                        <a 
                            href="https://www.facebook.com/csabasandor.szabo/"
                            rel="noreferrer"
                            target="_blank" 
                            className="footer-get-in-touch__link">
                            
                            <i className="fa-brands fa-facebook-f"></i>
                        </a>
                    </li>

                    <li className="footer-get-in-touch__item">
                        <a 
                            href="https://www.instagram.com/szabocsabasandor/" 
                            rel="noreferrer"
                            target="_blank" 
                            className="footer-get-in-touch__link">
                            
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                    </li>
                    
                    <li className="footer-get-in-touch__item">
                        <a 
                            href="mailto:szabocsabasandor90@gmail.com" 
                            className="footer-get-in-touch__link">
                            
                            <i className="fa-solid fa-envelope"></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="footer-navigation">
                <div className="footer-navigation__left">
                    <ul className="footer-navigation__list">
                        <li className="footer-navigation__item">
                            <NavLink to="/" className="footer-navigation__link">
                                {t('adventures')}
                            </NavLink>
                        </li>

                        <li className="footer-navigation__item">
                            <NavLink to="/about-me" className="footer-navigation__link">
                            {t('about_me')}
                            </NavLink>
                        </li>

                        <li className="footer-navigation__item">
                            <NavLink to="/about-me" className="footer-navigation__link">
                            {t('hobbies')}
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <div className="footer-navigation__right">
                    <p>Copyright &copy; 2022 by <a href="/" className="footer-navigation__link">Szabó Csaba-Sándor</a></p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;