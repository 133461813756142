import ImgBiography from '../assets/img/section-about-me/biography.jpg';
import ImgCareer from '../assets/img/section-about-me/career.jpg';
import ImgLifestyle from '../assets/img/section-about-me/lifestyle.jpg';
import { useTranslation } from "react-i18next";

const AboutMe = () => {
    const { t } = useTranslation();
    // const age = new Date().getFullYear() - 1990;
    document.title = `Szabó Csaba-Sándor - ${t('about_me')}`;

    return (
        <section className="section-about-me">
            <div className="breadcrumb">
            <h1 className="breadcrumb__title">{t('about_me')}</h1>
                <a href="/" className="breadcrumb__link">{t('home')}</a>
                <span className="breadcrumb__separator"> &gt; </span>
                <span className="breadcrumb__last">{t('about_me')}</span>
            </div>

            <div className="container">
                <div className="row-biography">
                    <div className="row-biography__column-text">
                        <h2 className="row-biography__title">{t('biography')}</h2>
                        <p className="row-biography__description">{t('about_me_description')}</p>
                    </div>

                    <div className="row-biography__column-photo">
                        <img src={ImgBiography} alt={t('biography')} className="row-biography__photo" />
                    </div>
                </div>

                <div className="row-biography">
                    <div className="row-biography__column-photo">
                        <img src={ImgCareer} alt={t('career')} className="row-biography__photo" />
                    </div>

                    <div className="row-biography__column-text">
                        <h2 className="row-biography__title">{t('career')}</h2>
                        <p className="row-biography__description">{t('career_description')}</p>
                    </div>
                </div>

                <div className="row-biography">
                    <div className="row-biography__column-text">
                        <h2 className="row-biography__title">{t('lifestyle')}</h2>
                        <p className="row-biography__description">{t('lifestyle_description')}</p>
                    </div>

                    <div className="row-biography__column-photo">
                        <img src={ImgLifestyle} alt={t('lifestyle')} className="row-biography__photo" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutMe;