import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navigationActions } from '../store/navigation';
import { useTranslation } from "react-i18next";

const Navigation = () => {
    const dispatch = useDispatch();
    const showNavigation = useSelector(state => state.navigation.showNavigation);
    const navigationClasses = useSelector(state => state.navigation.navigationClasses);
    const { t } = useTranslation();

    const toggleNavigationHandler = () => {
        dispatch(navigationActions.toggleNavigation());
        dispatch(navigationActions.toggleNavigationClasses());
    };

    let navigation = document.querySelector('.sidebar-navigation__menu');
    let navigationIcon = document.querySelector('.sidebar-navigation__icon');
        
    if (showNavigation) {
        navigation.classList.add('sidebar-navigation__menu--active');
        navigationIcon.classList.add('fa-xmark');

    } else {
        if (navigationIcon !== null) {
            navigation.classList.remove('sidebar-navigation__menu--active');
            navigationIcon.classList.add('fa-bars');
        }
    }

    return (
        <aside onClick={toggleNavigationHandler} className="sidebar-navigation">
            <i className={navigationClasses}></i>

            <nav className="sidebar-navigation__menu">
                <ul className="sidebar-navigation__menu-list">
                    <li className="sidebar-navigation__menu-item">
                        <NavLink to="/" className="sidebar-navigation__menu-link">{t('adventures')}</NavLink>
                    </li>

                    <li className="sidebar-navigation__menu-item">
                        <NavLink to="/about-me" className="sidebar-navigation__menu-link">{t('about_me')}</NavLink>
                    </li>

                    <li className="sidebar-navigation__menu-item">
                        <NavLink to="/hobbies" className="sidebar-navigation__menu-link">{t('hobbies')}</NavLink>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

export default Navigation;